<template>
  <div class="market-page container">
    <market-header tab-base-url="/shop-goods/goods"/>
    <market-extras/>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import MarketExtras from './market-extras';
import SvgIcon from '@/components/SvgIcon';
import MarketHeader from '@/views/shopGoods/market-header';

export default {
  name: 'pcFloorManage',
  components: {
    MarketHeader,
    MarketExtras,
    SvgIcon,
    draggable,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss">
.market-page {
}
</style>
